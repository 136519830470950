import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faHome } from "@fortawesome/free-solid-svg-icons"

const BlogPostTemplate = ({
  data: { previous, next, site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <div className="mb-8">
        <ul
          id="breadcrumb"
          className="flex flex-row gap-3 overflow-hidden text-xs"
        >
          <li>
            <Link to="/" title="Go to Homepage">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </li>
          <li>
            <Link to="/blog" title="Go to Blog">
              Blog
            </Link>
          </li>
          <li className="text-slate-500">{post.frontmatter.title}</li>
        </ul>
      </div>

      <article
        id="blog-post"
        className="prose lg:prose-lg prose-slate prose-code:before:content-none prose-code:after:content-none"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p className="text-slate-500">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            {post.fields.date}
          </p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <hr />
      <nav className="blog-post-nav mt-12">
        <ul
          className="flex sm:flex-row flex-col gap-4 justify-between text-sm"
        >
          <li>
            {previous && (
              <Link to={`/${previous.fields.slug}`} rel="prev" className="!text-cyan-800">
                <span className="flex items-center gap-2 justify-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-4 w-4"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg> {previous.frontmatter.title}</span>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/${next.fields.slug}`} rel="next" className="!text-cyan-800">
                <span className="flex items-center gap-2">{next.frontmatter.title} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-4 w-4"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 19.5l7.5-7.5-7.5-7.5"></path></svg></span>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  let featuredImgFluid = post?.frontmatter?.image?.childImageSharp?.fluid.src || null;

  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
      image={featuredImgFluid}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        date(formatString: "LL", locale: "es")
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
        date(formatString: "LL", locale: "es")
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
        date(formatString: "LL", locale: "es")
      }
      frontmatter {
        title
      }
    }
  }
`
